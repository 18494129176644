import type { CustomerUpdate } from '@commercelayer/sdk'
import type { User } from '../index'
import type { dataLayerSetupSchema } from '@integration-layer/schemas/GAEvents/dataLayerSetup'
import type { z } from 'zod'

const useClCustomer = async (customerId: string) => {
  const { $cl } = useNuxtApp()

  const { data: customerData } = await useAsyncData(
    'cl-customer',
    () =>
      $cl.customers.retrieve(customerId, {
        fields: ['status'],
      }),
    {
      server: false,
      dedupe: 'defer',
      getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
    }
  )

  return { customerData }
}

const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const useCustomer = async () => {
  const { $cl } = useNuxtApp()
  const { country } = useRouteHelper()

  const { loggedIn, user } = useUserSession()
  const isLoading = useLoaderState()

  if (!import.meta.client || !loggedIn.value) return {}

  const { formatDate, encodeDate } = useDateOfBirth()

  const { userMeta, userData, initUserMeta, refreshUserMeta } = useUserMeta()
  await initUserMeta()

  const customerAdapter = (): Omit<User, 'password'> => {
    const usr = user.value!

    const identity = userData?.value?.identities?.[0]
    const isSocialAccount = identity?.isSocial ?? false
    const connection = identity?.connection ?? ''

    return {
      id: usr.sub,
      email: userData.value?.email!,
      firstName: capitalize(userData.value?.given_name!),
      lastName: capitalize(userData.value?.family_name!),
      gender: (userMeta.value?.gender! as User['gender']) ?? '',
      dateOfBirth: userMeta.value?.date_of_birth
        ? formatDate(userMeta.value?.date_of_birth)
        : '',
      prefix: userMeta.value?.phone_prefix,
      phoneNumber: userMeta.value?.phone_number,
      contactAuthorization: userMeta.value?.contact_authorization ?? false,
      channelsPreference: userMeta.value?.channelsPreference ?? [],
      // set to country because the Lang showed at frontend uses the country code
      language: userMeta.value?.language?.toLowerCase?.() ?? '',
      country: userMeta.value?.country?.toLowerCase?.() ?? '',
      marketingAuthorization: userMeta.value?.marketing_authorization ?? false,
      brandPreference:
        (userMeta.value?.brandPreference as User['brandPreference']) ?? brands,
      account: {
        isSocial: isSocialAccount,
        connection: connection,
      },
    }
  }

  const { customerData } = userMeta?.value?.commercelayer_id
    ? await useClCustomer(userMeta.value.commercelayer_id)
    : { customerData: ref(null) }

  const customer = computed(() => customerAdapter())

  watch(
    customer,
    async () => {
      const userState =
        useGAEntity<z.infer<typeof dataLayerSetupSchema>['paramUser']>(
          'GA_DL_SETUP_USER'
        )
      const encryptedEmail = await digestSHA256(customer.value.email)
      const yearOfBirth = parseInt(
        customer.value.dateOfBirth.split('/')[2] ?? 0
      )
      const currentYear = new Date().getFullYear()
      const age = yearOfBirth !== 0 ? currentYear - yearOfBirth : 0

      const dateObject = new Date(userData.value?.created_at ?? '')
      const registrationDate = dateObject.toLocaleDateString('en-GB')

      function getRangeCategory(value: number) {
        if (value >= 0 && value <= 20) {
          return '0-20'
        } else if (value > 20 && value <= 30) {
          return '20-30'
        } else if (value > 30 && value <= 40) {
          return '30-40'
        } else if (value > 40 && value <= 60) {
          return '40-60'
        } else {
          return '60+'
        }
      }

      const formattedPrefix =
        '+' + (userMeta.value?.phone_prefix.split('+')?.[1] ?? '')
      userState.value = {
        userLogged: customer.value.id ? 1 : 0,
        userID: userMeta.value?.salesforce_id ?? '',
        registrationDate,
        userType: 'standard', // wait for backend for more advances types
        userGender: customer.value.gender ?? 'unknown',
        registeredNewsletter: customer.value.marketingAuthorization ? 1 : 0,
        userAge: yearOfBirth.toString(),
        cluster_age: getRangeCategory(age),
        SHA256_hashed_userEmail: encryptedEmail,
        userEmail: customer.value.email,
        userName: customer.value.firstName,
        userSurname: customer.value.lastName,
        userPhone: formattedPrefix + (customer.value.phoneNumber ?? ''),
      }
    },
    { immediate: true }
  )

  const customerStatus = customerData.value?.status

  const updateCustomer = async (
    customerUpdate: Omit<CustomerUpdate, 'id'>,
    triggerLoading = true
  ) => {
    //DISATTIVATO IN QUANTO NON NECESSARIO
    // customerData.value = await $cl.customers.update({
    //   id: userMeta.value.commercelayer_id,
    //   ...customerUpdate,
    // })

    if (customerUpdate?.metadata?.dateOfBirth) {
      customerUpdate.metadata!.dateOfBirth! = encodeDate(
        customerUpdate!.metadata!.dateOfBirth!,
        country
      )
    }

    if (customerUpdate.metadata) {
      customerUpdate.metadata.email = userData.value?.email!
    }

    const body = {
      data: customerUpdate,
      auth0Id: user.value?.sub,
      clId: userMeta.value.commercelayer_id,
      sfId: userMeta.value?.salesforce_id!,
      excludeUserData: customer?.value?.account?.isSocial,
    }

    if (triggerLoading) isLoading.value = true
    await $fetch('/api/user/updateUser', {
      method: 'POST',
      body: body,
    }).catch(() => {
      if (triggerLoading) isLoading.value = false
    })
    await refreshUserMeta().catch(() => {
      if (triggerLoading) isLoading.value = false
    })
    if (triggerLoading) isLoading.value = false
  }
  const updateCustomerMetadata = async (
    metadata: CustomerUpdate['metadata'],
    triggerLoading = true
  ) => {
    updateCustomer(
      {
        metadata,
      },
      triggerLoading
    )
  }

  return {
    customer,
    customerStatus,
    updateCustomer,
    updateCustomerMetadata,
    userData,
    userMeta,
    refreshUserMeta,
  }
}
